@import "../node_modules/bootstrap/scss/bootstrap";
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");

:root {
  --bs-font-sans-serif: "Source Sans Pro", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  --bs-body-font-weight: 500;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.request-container,
.request-container.has-sidebar {
  width: 1200px;
}

.accordion-button:focus {
  outline: gold 2px solid;
}

/* Use default user-agent fieldset styling */
.default-fieldset {
  min-width: revert;
  border: revert;
  margin: revert;
  padding: revert;
}

.default-fieldset + .default-fieldset {
  margin-top: 0.5rem;
}

/* Use default user-agent legend styling */
.default-legend {
  float: revert;
  width: revert;
  padding: revert;
  margin: revert;
}

.tooltip {
  --bs-tooltip-bg: var(--bs-light);
  --bs-tooltip-color: black;
}

.draggable-table-button:focus-visible {
  outline: #86b7fe solid 3px;
  outline-offset: -5px;
}

.copy-credentials-button:focus-visible {
  outline: #86b7fe solid 3px;
}
